/* eslint-disable react/no-children-prop */
import React from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'

import {SpaceIcon, ShiftIcon, PsycheIcon, PersonaIcon, MemoryIcon, LEDIcon} from './Icons'

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false
  })

  const toggleDrawer = (anchor:string, open:boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const list = (anchor: string) => (
    <div
      style={{ width: '250px' }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        {[
          { name: 'Home', id: 'home' , Icons: (<span className="text-2xl"><HomeIcon /></span>)},
          { name: 'Letter from the Editor', id: 'letter-from-editor', Icons: (<span className="text-2xl"><LEDIcon /></span>)  },
          { name: 'I. MEMORY', id: 'memory', Icons: (<span className="text-2xl"><MemoryIcon /></span>) },
          { name: 'II. PLACE', id: 'place', Icons: (<span className="text-2xl"><SpaceIcon /></span>)},
          { name: 'III. SHIFT', id: 'shift', Icons: (<span className="text-2xl"><ShiftIcon /></span>)},
          { name: 'IV. PSYCHE', id: 'psyche', Icons: (<span className="text-2xl"><PsycheIcon /></span>) },
          { name: 'V. PERSONA', id: 'persona', Icons: (<span className="text-2xl"><PersonaIcon /></span>) },
          { name: 'Footer', id: 'footer', Icons: (<span className="rounded-full w-6 h-6 bg-red-700"></span>)  }
        ].map((text, index) => (
          <ListItem button key={text.name}>
            <ListItemIcon children={text.Icons} />
            <a href={`#${text.id}`}>
              {text.name}
            </a>
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <div>
      <React.Fragment key="left">
        <Button onClick={toggleDrawer('left', true)}>
          <MenuIcon />
        </Button>
        <SwipeableDrawer
          anchor="left"
          open={state.left}
          onClose={toggleDrawer('left', false)}
          onOpen={toggleDrawer('left', true)}>
          {list('left')}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  )
}

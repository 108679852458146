export const posts:{
    [key: string]: [string, string, ...TemplateStringsArray]} = {
    one: [
        'a-community-built-by-change-the-many-movements-of-the-punjabi-diaspora',
        'ghost-month-in-penang-an-island-haunted-by-the-spectre-of-dengue-fever',
        'it-might-actually-be-good-to-confront-your-friends-about-middle-school-drama',
        'the-generation-that-broke-all-the-rules-torontos-hippie-life-in-the-60s'
    ],
    two: [
        'a-love-letter-to-my-favorite-plants-as-told-through-illustrations',
        'tik-tok-at-u-of-t-how-students-have-made-it-big-on-the-viral-app',
        'what-it-means-to-pack-a-suitcase-—-lessons-from-my-late-father',
        'build-your-own-batcave-thang-vu-on-what-it-means-to-be-an-artist-today'
    ], 
    three: [
        'making-bread-during-covid-19-life-on-the-frontlines-doing-minimum-wage-and-unpaid-work',
        'feeling-stuck-as-first-year-during-the-pandemic',
        'flying-in-circles-on-adulthood-and-university-from-your-childhood-bedroom',
        'living-in-motion-pictures-a-stop-motion-animation-photo-essay',
        'how-listening-to-plants-helped-me-believe-in-people-again',
        'redefining-relationships-the-lockdowns-effect-on-the-familial-platonic-and-romantic',
    ],
    four: [
        'my-dreamland-rolodex-escapism-as-a-source-of-relief-—-and-why-its-okay',
        'what-to-do-when-youre-barely-hanging-on-to-hope',
        'misinformation-uncertainty-and-conspiracies-oh-my',
        'slouching-toward-the-sky-finding-god-on-an-afternoon-run',
        'making-bread-during-covid-19-life-on-the-frontlines-doing-minimum-wage-and-unpaid-work'
    ], 
    five: [
        'gardens-of-the-future-how-your-plants-at-home-can-help-address-national-food-security',
        'guide-to-being-a-well-informed-global-citizen-during-the-pandemic',
        'my-connotated-urban-spaces-what-happened-when-i-lost-my-childhood-playground',
        'theatre-criticism-has-come-to-a-fork-in-the-road',
        'toronto-transplant-my-friends-share-how-the-city-compares-to-their-distant-homes'
    ]
}

const sectionNumber = new Map([
    ['a-community-built-by-change-the-many-movements-of-the-punjabi-diaspora', 'one'],
    ['ghost-month-in-penang-an-island-haunted-by-the-spectre-of-dengue-fever', 'one'],
    ['it-might-actually-be-good-to-confront-your-friends-about-middle-school-drama', 'one'],
    ['toronto-transplant-my-friends-share-how-the-city-compares-to-their-distant-homes', 'one'],
    ['a-love-letter-to-my-favorite-plants-as-told-through-illustrations','two'],
    ['tik-tok-at-u-of-t-how-students-have-made-it-big-on-the-viral-app','two'],
    ['what-it-means-to-pack-a-suitcase-—-lessons-from-my-late-father','two'],
    ['build-your-own-batcave-thang-vu-on-what-it-means-to-be-an-artist-today', 'two'],
    ['flying-in-circles-on-adulthood-and-university-from-your-childhood-bedroom','three'],
    ['living-in-motion-pictures-a-stop-motion-animation-photo-essay', 'three'],
    ['how-listening-to-plants-helped-me-believe-in-people-again', 'three'],
    ['redefining-relationships-the-lockdowns-effect-on-the-familial-platonic-and-romantic', 'three'],
    ['my-dreamland-rolodex-escapism-as-a-source-of-relief-—-and-why-its-okay','four'],
    ['what-to-do-when-youre-barely-hanging-on-to-hope', 'four'],
    ['misinformation-uncertainty-and-conspiracies-oh-my', 'four'],
    ['slouching-toward-the-sky-finding-god-on-an-afternoon-run', 'four'],    
    ['making-bread-during-covid-19-life-on-the-frontlines-doing-minimum-wage-and-unpaid-work', 'four'],
    ['gardens-of-the-future-how-your-plants-at-home-can-help-address-national-food-security', 'five'],
    ['making-bread-during-covid-19-life-on-the-frontlines-doing-minimum-wage-and-unpaid-work','three'],
    ['feeling-stuck-as-a-first-year-during-the-pandemic', 'three'],
    ['guide-to-being-a-well-informed-global-citizen-during-the-pandemic', 'five'],
    ['my-connotated-urban-spaces-what-happened-when-i-lost-my-childhood-playground', 'five'],
   ['theatre-criticism-has-come-to-a-fork-in-the-road','five'],
    ['toronto-transplant-my-friends-share-how-the-city-compares-to-their-distant-homes','five']
])

export const previousArticle = (slug?: string)=> {
    const section = slug ? sectionNumber.get(slug) : 'one'
    const subsection = section ? posts[`${section}`] : ['']
    const length = section ? subsection.length : 1
    const previousIndex = slug ? (subsection.indexOf(slug)-1+length)%length : 0
    return (subsection[previousIndex])

}

export const nextArticle = (slug?: string)=> {
    const section = slug ? sectionNumber.get(slug) : 'one'
    const subsection = section ? posts[`${section}`] : ['']
    console.log(slug,  'blah')
    const length = section ? subsection.length : 1
    const nextIndex = slug ? (subsection.indexOf(slug)+1+length)%length : 0
    console.log(length, nextIndex, subsection)
    return (subsection[nextIndex])

}
import React from "react";
import {  useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image'

export const AdsImage2 =()=> {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "ads_image.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Img className="rounded-md shadow-md" fluid={data.placeholderImage.childImageSharp.fluid} />
  )
}
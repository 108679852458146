import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "./footer";
import Header from "../components/header";
import "../styles/index.css";
import {isMobile} from 'react-device-detect'
import useSiteMetadata from "../hooks/useSiteMetadata";
import { withPrefix , Link} from "gatsby";
import {previousArticle, nextArticle} from '../utils/posts'
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';

type Props = {
  children: React.ReactChild | React.ReactChild[]
  isHome: boolean, 
  postTitle?: string, 
  postDescription?: string, 
  postImage?: any,
  slug?: string,  
  section?: string
  className?: string
  displayArrows?: boolean
}

const TemplateWrapper = ({ children, isHome , postTitle, postDescription, postImage, section, slug, className = "", displayArrows = true}: Props) => {
  const { title, description } = useSiteMetadata();
  const nextArticleSlug = nextArticle(slug);
  const previousArticleSlug = previousArticle(slug);
  useEffect(() => {
    console.log("Before loading");
    if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      require("smooth-scroll")('a[href*="#"]');
      console.log("Loaded smooth scroll");
    }
  }, []);

  return (
    <div>
  <Helmet>
    <html lang="en" />
    <title>{title}</title>
    <meta name="description" content={description} />
    <title>{postTitle || title}</title>
    <meta name="description" content={postDescription || description} />
    <meta property="og:type" content="business.business" />
    <meta property="og:title" content={postTitle || title} />
    <meta property="og:url" content="/" />
    <meta
      property="og:image"
      content={`${ postImage && ("https://flux.thevarsity.ca" + postImage.src) || withPrefix("/") + "img/og-image.jpg"}`}
    />
    <meta name="twitter:image" content={`${ postImage && ("https://flux.thevarsity.ca" + postImage.src) || withPrefix("/") + "img/og-image.jpg"}`} />
    <meta property="og:description" content={postDescription || description} />
    <link rel="icon" type="image/svg" href="/varsityLogo.svg"></link>
  </Helmet>
  <Header siteTitle={title} isHome={isHome} />
  <div className={className}>{children}</div>
  {!isHome && displayArrows && (
    <>
      <div className="fixed inset-y-0 right-0 z-30 mt-72 next-icon">
        {/* @ts-ignore Styled JSX*/}
        <Link to={nextArticleSlug ? `/article/${nextArticleSlug}` : '/'}>
          <ArrowForwardIosSharpIcon
            style={{
              fontSize: isMobile ? 10 : 40,
              height: isMobile ? 20 : 40,
              width: isMobile ? 20 : 40,
            }}
          />
        </Link>
      </div>
      <div className="fixed inset-y-0 left-0 z-30 mt-72 previous-icon">
        {/* @ts-ignore Styled JSX*/}
        <Link to={previousArticleSlug ? `/article/${previousArticleSlug}` : '/'}>
          <ArrowBackIosSharpIcon
            style={{
              fontSize: isMobile ? 10 : 40,
              height: isMobile ? 20 : 40,
              width: isMobile ? 20 : 40,
            }}
          />
        </Link>
      </div>
    </>
  )}
  <Footer isHome={isHome} />
</div>
  );
};

export default TemplateWrapper;
import React, { useState, FunctionComponent, ReactElement } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Drawers from './drawers'

import { Slide } from 'react-reveal'

// import { HomeIcon, SearchIcon } from './Icons';
import {  isMobile } from 'react-device-detect'
import Search from './search'

interface HeaderProps {
  siteTitle: string
  isHome: boolean
}

const Header: FunctionComponent<HeaderProps> = ({
  siteTitle,
  isHome,
}: HeaderProps): ReactElement => {
  const [searchToggle, setSearchToggle] = useState(false);

  if (isHome) {
    return (
      <StaticQuery
        query={graphql`
          query SearchIndexQuery {
            siteSearchIndex {
              index
            }
          }
        `}
        render={(data) => (
          <header className="bg-purple-800 mb-6" style={
            {
              fontFamily: 'Chivo'
            }
          }>
            <div>
              <nav className="bg-white shadow w-screen z-10 fixed top-0 left-0 w-full" style={{maxWidth: '100vw'}}>
                <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-4">
                  <div className="relative flex justify-between h-12">
                    <ul
                      className="px-4"
                      style={{
                        width: "100vw",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <li className="nav-item text-gray-700">
                        <Drawers />
                      </li>
                      {isMobile && searchToggle ? null : (
                        <li className="nav-item text-gray-700  absolute text-center" style={{
                          flex: '1 1', left: isMobile? '33vw': '45%'
                        }}>
                          <a
                            href="/"
                            className="nav-link p-2 lg:p-6 text-black font-bold italic text-2xl"
                          >
                            {siteTitle}
                          </a>
                        </li>
                      )}
                      <li className="nav-item flex-shrink-0">
                        <div className="flex flex-row justify-center">
                          <Slide
                            right
                            when={searchToggle}
                            style={{
                              display: searchToggle ? "inline" : "none",
                              visibility: 'collapse'
                            }}
                          >
                            <Search
                              searchIndex={data.siteSearchIndex.index}
                              toggle={searchToggle}
                            />
                          </Slide>
                          <a
                            className="navbar-item"
                            onClick={() => {
                              setSearchToggle(!searchToggle);
                            }}
                          >
                            <button className="lg:absolute top-0 mt-3 lg:mr-2 focus:outline-none">
                              {" "}
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                ></path>
                              </svg>
                            </button>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </header>
        )}
      />
    );
  } else {
    return (
      <div>
        <nav className="bg-white shadow z-10 fixed top-0 left-0 w-full h-12">
          <div className="flex justify-center items-center w-full">
            <a href="/">
              <p className="text-2xl uppercase text-black italic font-bold mt-2" style={
            { 
              fontFamily: 'Chivo'
            }}>{siteTitle}</p>
            </a>
          </div>
        </nav>
      </div>
    );
  }
};

export default Header;